import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IColumn, ComboBox } from "@fluentui/react";
import {
  GetMitarbeiterDocument,
  Mitarbeiter,
  MitarbeiterInput,
  SaveMitarbeiterMutation,
  User,
  useGetMitarbeiterQuery,
  useSaveMitarbeiterMutation,
} from "../gql/graphql";
import DetailsListEditable from "../components/DetailsListEditable";
import { TextBoxColumn } from "../components/TextBoxColumn";
import { Page } from "../components/Page";
import { Checkbox } from "@fluentui/react-components";

export function MitarbeiterView(props: { user: User }) {
  const { data, loading } = useGetMitarbeiterQuery();
  const [loadet, setLoadet] = useState(false);
  const [mitarbeiterarr, setMitarbeiterarr] = useState<
    | (Partial<Mitarbeiter> & {
        rowid: number;
        edited: boolean;
        removed: boolean;
      })[]
    | undefined
  >();

  const [save] = useSaveMitarbeiterMutation({
    refetchQueries: () => [{ query: GetMitarbeiterDocument }],
  });
  useEffect(() => {
    if (!loadet && !loading) {
      setLoadet(true);
      const sortedList = [...(data?.mitarbeiter ?? [])]
        .sort((a, b) => {
          if (a.eingetragen && b.eingetragen) {
            return (
              new Date(a.eingetragen).valueOf() -
              new Date(b.eingetragen).valueOf()
            );
          } else {
            return (a.id ?? 0) - (b.id ?? 0);
          }
        })
        .map((pr, i) => ({
          ...pr,
          edited: false,
          removed: false,
          rowid: i,
        }))
        .sort((a, b) => b.rowid - a.rowid);
      // Update the state with a copy of the sorted list
      setMitarbeiterarr(sortedList);
    }
  }, [loadet, loading, data?.mitarbeiter]);

  function useHandleOfMitarbeiter(
    key: keyof (Partial<Mitarbeiter> & {
      rowid: number;
      edited: boolean;
      removed: boolean;
    }),
    isNumeric?: boolean,
    isBoolean?: boolean
  ) {
    return useCallback(
      (
        belag?: Partial<Mitarbeiter> & {
          rowid: number;
          edited: boolean;
          removed: boolean;
        },
        val?: string,
        index?: number
      ) => {
        if (index !== undefined) {
          setMitarbeiterarr((prevBelagsliste) =>
            prevBelagsliste?.map((item, i) => {
              if (item.rowid === index) {
                return {
                  ...item,
                  [key]: isNumeric
                    ? Number(val)
                    : isBoolean
                    ? val === "true"
                    : val,
                };
              }
              return item;
            })
          );
        }
      },
      [key, isNumeric, isBoolean]
    );
  }

  const handleFunktionChange = useHandleOfMitarbeiter("funktion");
  const handleInaktivChange = useHandleOfMitarbeiter("inaktiv", true);
  const handleNameChange = useHandleOfMitarbeiter("name");
  const handleVornameChange = useHandleOfMitarbeiter("vorname");
  const handlePersonalChange = useHandleOfMitarbeiter("personalnr");
  const handleMobileChange = useHandleOfMitarbeiter("mobile");
  const handleFirmaChange = useHandleOfMitarbeiter("firma", true);
  const handleStateChange = useHandleOfMitarbeiter("edited", false, true);

  const handleMutateComplete = useCallback(
    (res: SaveMitarbeiterMutation) => {
      if (res.mitarbeiter.save.added && res.mitarbeiter.save.added !== null) {
        let nums = Math.max(...(mitarbeiterarr?.map((p) => p.rowid) ?? [])) + 1;
        let vals = res.mitarbeiter.save.added.map((pr, num) => {
          let i = nums + num;
          return { ...pr, rowid: i, edited: false, removed: false };
        });
        if (mitarbeiterarr) {
          let tmparr = [
            ...mitarbeiterarr.filter(
              (pr) => pr.id && pr.removed === false && pr.edited === false
            ),
            ...vals,
          ].sort((a, b) => b.rowid - a.rowid);
          setMitarbeiterarr([...tmparr]);
        }
      }
    },
    [mitarbeiterarr]
  );

  const onRenderPersonalNr = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<
          Mitarbeiter & { rowid: number; edited: boolean; removed: boolean }
        >
          item={
            item as Mitarbeiter & {
              rowid: number;
              edited: boolean;
              removed: boolean;
            }
          }
          keyOfItem="personalnr"
          onChange={(e, v) => {
            handlePersonalChange(item, v, item?.rowid);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", item?.rowid);
            }
          }}
        />
      );
    },
    [handlePersonalChange, handleStateChange]
  );

  const onRenderName = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<
          Mitarbeiter & { rowid: number; edited: boolean; removed: boolean }
        >
          item={
            item as Mitarbeiter & {
              rowid: number;
              edited: boolean;
              removed: boolean;
            }
          }
          keyOfItem="name"
          onChange={(e, v) => {
            handleNameChange(item, v, item?.rowid);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", item?.rowid);
            }
          }}
        />
      );
    },
    [handleNameChange, handleStateChange]
  );

  const onRenderInaktiv = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <Checkbox
          checked={Number(item?.inaktiv) === 1}
          onChange={(e, d) => {
            handleInaktivChange(
              item,
              d.checked === true ? "1" : "0",
              item?.rowid
            );
            handleStateChange(item, "true", item?.rowid);
          }}
        />
      );
    },
    [handleInaktivChange, handleStateChange]
  );

  const onRenderVorname = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<
          Mitarbeiter & { rowid: number; edited: boolean; removed: boolean }
        >
          item={
            item as Mitarbeiter & {
              rowid: number;
              edited: boolean;
              removed: boolean;
            }
          }
          keyOfItem="vorname"
          onChange={(e, v) => {
            handleVornameChange(item, v, item?.rowid);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", item?.rowid);
            }
          }}
        />
      );
    },
    [handleVornameChange, handleStateChange]
  );
  const onRenderMobil = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<
          Mitarbeiter & { rowid: number; edited: boolean; removed: boolean }
        >
          item={
            item as Mitarbeiter & {
              rowid: number;
              edited: boolean;
              removed: boolean;
            }
          }
          keyOfItem="mobile"
          onChange={(e, v) => {
            handleMobileChange(item, v, item?.rowid);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", item?.rowid);
            }
          }}
        />
      );
    },
    [handleMobileChange, handleStateChange]
  );

  const onRenderFirma = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<
          Mitarbeiter & { rowid: number; edited: boolean; removed: boolean }
        >
          item={
            item as Mitarbeiter & {
              rowid: number;
              edited: boolean;
              removed: boolean;
            }
          }
          keyOfItem="firma"
          onChange={(e, v) => {}}
          onFocusGoneConvertingMethode={(str) => {
            return str === "1" ? "Tozzo" : "Schleith";
          }}
        >
          <ComboBox
            selectedKey={item?.firma === 1 ? "Tozzo" : "Schleith"}
            onChange={(e, o, i, v) => {
              let input = v === "Tozzo" ? "1" : "2";
              handleFirmaChange(item, input, item?.rowid);
              if (item?.id !== null && item?.id !== undefined) {
                handleStateChange(item, "true", item?.rowid);
              }
            }}
            options={["Tozzo", "Schleith"].map((pr) => ({
              key: pr,
              text: pr,
            }))}
          />
        </TextBoxColumn>
      );
    },
    [handleFirmaChange, handleStateChange]
  );

  const onRenderFunktion = useCallback(
    (
      item?: Mitarbeiter & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<
          Mitarbeiter & { rowid: number; edited: boolean; removed: boolean }
        >
          item={
            item as Mitarbeiter & {
              rowid: number;
              edited: boolean;
              removed: boolean;
            }
          }
          keyOfItem="funktion"
          onChange={(e, v) => {}}
        >
          <ComboBox
            selectedKey={item?.funktion}
            onChange={(e, o, i, v) => {
              handleFunktionChange(item, v, item?.rowid);
              if (item?.id !== null && item?.id !== undefined) {
                handleStateChange(item, "true", item?.rowid);
              }
            }}
            options={["Bauführer", "Vorarbeiter"].map((pr) => ({
              key: pr,
              text: pr,
            }))}
          />
        </TextBoxColumn>
      );
    },
    [handleFunktionChange, handleStateChange]
  );

  const wartungcolumns: IColumn[] = useMemo(
    () => [
      {
        key: "firma",
        name: "Firma",
        fieldName: "firma",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderFirma,
      },
      {
        key: "name",
        name: "Name",
        fieldName: "name",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderName,
      },
      {
        key: "vorname",
        name: "Vorname",
        fieldName: "vorname",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderVorname,
      },
      {
        key: "mobile",
        name: "Mobile",
        fieldName: "mobile",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderMobil,
      },
      {
        key: "personalnr",
        name: "Personal Nr",
        fieldName: "personalnr",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderPersonalNr,
      },
      {
        key: "funktion",
        name: "Funktion",
        fieldName: "funktion",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderFunktion,
      },
      {
        key: "inaktiv",
        name: "Inaktiv",
        fieldName: "inaktiv",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderInaktiv,
      },
    ],
    [
      onRenderPersonalNr,
      onRenderFirma,
      onRenderMobil,
      onRenderVorname,
      onRenderName,
      onRenderFunktion,
      onRenderInaktiv,
    ]
  );

  const mafiltered = useMemo(()=> mitarbeiterarr?.filter((pr) => pr.removed !== true) ?? [],[mitarbeiterarr]);
  return !loading ? (
    <Page title="Mitarbeiter">
      <DetailsListEditable<Partial<Mitarbeiter> & { rowid: number }>
        hasSave
        searchable
        onSaveClick={() => {
          let updatables =
            mitarbeiterarr
              ?.filter((pr) => pr.edited && pr.removed === false)
              .filter(
                (valobj) =>
                  valobj.firma !== undefined &&
                  (valobj.name ?? "").length > 0 &&
                  (valobj.vorname ?? "").length > 0 &&
                  valobj.funktion !== undefined &&
                  valobj.id !== undefined
              )
              .map<MitarbeiterInput>((pr) => ({
                firma: pr.firma ?? 0,
                funktion: pr.funktion ?? "",
                inaktiv: pr.inaktiv ?? 0,
                name: pr.name ?? "",
                personalnr: pr.personalnr,
                vorname: pr.vorname ?? "",
                id: pr.id ?? 0,
                mobile: pr.mobile,
              })) ?? [];
          let removable =
            mitarbeiterarr
              ?.filter(
                (pr) => pr.removed && pr.id !== undefined && pr.id !== null
              )
              .map((pr) => pr.id ?? 0) ?? [];
          let addable =
            mitarbeiterarr
              ?.filter((pr) => pr.id === null || pr.id === undefined)
              .filter(
                (valobj) =>
                  valobj.firma !== undefined &&
                  (valobj.name ?? "").length > 0 &&
                  (valobj.vorname ?? "").length > 0 &&
                  valobj.funktion !== undefined
              )
              .map<MitarbeiterInput>((pr) => ({
                firma: pr.firma ?? 0,
                funktion: pr.funktion ?? "",
                inaktiv: pr.inaktiv ?? 0,
                name: pr.name ?? "",
                personalnr: pr.personalnr,
                vorname: pr.vorname ?? "",
                mobile: pr.mobile,
              })) ?? [];

          if (
            updatables.length > 0 ||
            addable.length > 0 ||
            removable.length > 0
          ) {
            save({
              variables: {
                addmitarbeiter: addable,
                updatemitarbeiter: updatables,
                delMitarbeiter: removable,
              },
              onCompleted: handleMutateComplete,
            });
          }
        }}
        filtersTitles={["Firma, Name, Vorname, Mobile, Personal Nr und Funktion"]}
        data={mafiltered}
        onAdd={() => {
          let tmp = mitarbeiterarr?.map((pr) => pr.rowid) ?? [];
          let wart: Partial<Mitarbeiter> & {
            rowid: number;
            edited: boolean;
            removed: boolean;
          } = {
            firma: 1,
            funktion: "Bauführer",
            rowid: Math.max(...tmp) + 1,
            removed: false,
            edited: false,
          };
          let tmpsorted = [...(mitarbeiterarr ?? []), wart]?.sort(
            (a, b) => b.rowid - a.rowid
          );
          setMitarbeiterarr(tmpsorted);
        }}
        onRemoveClick={(selected) => {
          let newmitarbeiterarr = mitarbeiterarr?.filter(
            (pr) => selected?.map((t) => t.rowid).includes(pr.rowid) === false
          );
          newmitarbeiterarr = [
            ...(newmitarbeiterarr ?? []),
            ...selected
              .filter((a) => a.id !== null && a.id !== undefined)
              .map((k) => ({ ...k, removed: true, edited: false })),
          ];
          if (newmitarbeiterarr) {
            setMitarbeiterarr([...newmitarbeiterarr]);
          }
        }}
        columns={wartungcolumns}
      />
    </Page>
  ) : (
    <></>
  );
}
