import { useCallback } from "react";

export function useHandleOfType<T>(
  key: keyof (T & { rowid: number }),
  setter: React.Dispatch<
    React.SetStateAction<(T & { rowid: number })[] | undefined>
  >,
  isNumeric?: boolean,
  isBool?: boolean
) {
  return useCallback(
    (element?: T, val?: string, index?: number) => {
      if (index !== undefined && val !== undefined) {
        setter((prevList: (T & { rowid: number })[] | undefined) =>
          prevList?.map((item, i) => {
            if (item.rowid === index) {
              return {
                ...item,
                [key]: isNumeric
                  ? Number(val)
                  : isBool
                  ? val === "true"
                    ? true
                    : false
                  : val,
              };
            }
            return item;
          })
        );
      }
    },
    [key, isNumeric, setter,isBool]
  );
}
